// Set this to your tracking ID
const trackingId = 'UA-133373336-1';

console.log('loaded sw-analytics, registering handlers...');

self.addEventListener('install', function(event) {
  console.log('install event');
  event.waitUntil(sendAnalyticsEvent('service-worker-install', 'service-worker'));
});

self.addEventListener('activate', function(event) {
  console.log('activate event');
  event.waitUntil(sendAnalyticsEvent('service-worker-activate', 'service-worker'));
});

self.addEventListener('fetch', function(event) {
  console.log('fetch event');
  event.waitUntil(parseGraphQLFetchAndSend(event));
});

function parseGraphQLFetchAndSend(event) {
  const url = event.request.url;
  return event.request.text().then(function(body) {
    let eventValue = url;
    if(url.match(/graphql$/)) {
      eventValue = body
        .match(/ (search\([^)]*\))/);
      if (eventValue && eventValue.length > 1) {
        eventValue = eventValue[1]
          .replace(/(\d\.\d{2})\d+/g, '$1')
          .replace(/(l\w)\w+/g, '$1')
      } else {
        eventValue = url;
      }
    }
    return sendAnalyticsEvent('service-worker-fetch', 'service-worker', eventValue);
  });
}

function sendAnalyticsEvent(eventAction, eventCategory, ev) {
  'use strict';

  console.log('Sending analytics event: ' + eventCategory + '/' + eventAction);

  if (!trackingId) {
    console.error('You need your tracking ID in analytics-helper.js');
    console.error('Add this code:\nvar trackingId = \'UA-XXXXXXXX-X\';');
    // We want this to be a safe method, so avoid throwing unless absolutely necessary.
    return Promise.resolve();
  }

  if (!eventAction && !eventCategory) {
    console.warn('sendAnalyticsEvent() called with no eventAction or ' +
      'eventCategory.');
    // We want this to be a safe method, so avoid throwing unless absolutely necessary.
    return Promise.resolve();
  }

  return self.registration.pushManager.getSubscription()
    .then(function(subscription) {
      if (subscription === null) {
        throw new Error('No subscription currently available.');
      }

      // Create hit data
      const payloadData = {
        ni: 1,
        // Version Number
        v: 1,
        // Client ID
        cid: subscription.endpoint,
        // Tracking ID
        tid: trackingId,
        // Hit Type
        t: 'event',
        // Event Category
        ec: eventCategory,
        // Event Action
        ea: eventAction,
        // Event Label
        el: 'serviceworker',
        ev
      };

      // Format hit data into URI
      const payloadString = Object.keys(payloadData)
        .filter(function(analyticsKey) {
          return payloadData[analyticsKey];
        })
        .map(function(analyticsKey) {
          return analyticsKey + '=' + encodeURIComponent(payloadData[analyticsKey]);
        })
        .join('&');

      // Post to Google Analytics endpoint
      return fetch('https://www.google-analytics.com/collect', {
        method: 'post',
        body: payloadString
      });
    })
    .then(function(response) {
      if (!response.ok) {
        return response.text()
          .then(function(responseText) {
            throw new Error(
              'Bad response from Google Analytics:\n' + response.status
            );
          });
      } else {
        console.log(eventCategory + '/' + eventAction +
          'hit sent, check the Analytics dashboard');
      }
    })
    .catch(function(err) {
      console.warn('Unable to send the analytics event', err);
    });
}